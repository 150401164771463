import React from "react";
import { Lock } from "react-feather";
import CustomDataTableIcons from "src/components/Table/CustomDataTableIcons";
import TableTitle from "src/components/Table/TableTitle";
import { CustomDatatableV2 } from "src/pages/private/styles";
import {
  formatDateWithTime,
  makeDefaultTableOptions,
} from "src/storage/helpers";
const options = makeDefaultTableOptions("lockout-report");

const columns = [
  {
    name: "id",
    label: "ID",
    options: {
      filter: false,
      sort: false,
      display: false,
      download: false,
      print: false,
    },
  },
  {
    name: "module",
    label: "Module Name",
  },
  {
    name: "user",
    label: "Team Member",
  },
  {
    name: "location",
    label: "Location",
  },
  {
    name: "lockoutTime",
    label: "Lockout Time",
    options: {
      customBodyRender: (val) => (
        <span style={{ fontSize: 12 }}>{formatDateWithTime(val)}</span>
      ),
    },
  },
  {
    name: "duration",
    label: "Lockout Duration",
    options: {
      customBodyRender: (val) => <span>{`${val} minutes`}</span>,
    },
  },
];

const LockoutTable = ({ lockouts }) => {
  const tableData = lockouts?.map((lockout) => ({
    id: lockout.id,
    module: lockout.quiz_name,
    user: `${lockout.first_name} ${lockout.last_name}`,
    location: lockout?.active_team_member_locations.join(", ") || "--/--",
    lockoutTime: new Date(lockout.created),
    duration: lockout.lockout_duration,
  }));

  return (
    <CustomDatatableV2
      title={<TableTitle title="Module Lockouts" Icon={Lock} />}
      data={tableData}
      columns={columns}
      options={options}
      components={{
        icons: CustomDataTableIcons,
      }}
    />
  );
};

export default LockoutTable;
