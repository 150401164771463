export const AUTH_KEY = "SPIFFY_asfdaskjakjfsgoleafsgoasdlfkasdf";
export const REFRESH_KEY = "SPIFFY_REF_mdkqerouhjcxzmnbnadfjhuioehr";

export const ACCESS_LEVELS = {
  WRITE: "WRITE",
  NONE: "NO_ACCESS",
  READ_ONLY: "READ_ONLY",
};

export const JOB_LEVELS = {
  APP_USER: "A",
  ADMIN: "C",
  SUPERVISOR: "M",
};

export const THEMES = {
  DEFAULT: "DEFAULT",
  DARK: "DARK",
};

export const FEEDBACK_MESSAGES = {
  GENERIC_ERROR: "Oops.. we have run into an issue, please try again",
  // Can change this into w/e you want
};

export const AMAZON_IMAGE_URL = process.env.REACT_APP_IMAGE_S3_DOMAIN;

export const QUIZSTATES = {
  LIVE: "Live",
  CLOSED: "Closed",
  IN_DEVELOPMENT: "In Development",
  PENDING: "Pending Release",
  CONVERSION: "Content Conversion In Progress",
  IN_REVIEW: "Spiffy Review",
};

export const USER_TYPES = {
  RETAIL_ADMIN: "RETAIL_ADMIN",
  RETAIL_MANAGER_CAN_DEPLOY: "RETAIL_MANAGER_CAN_DEPLOY",
  RETAIL_MANAGER_CANT_DEPLOY: "RETAIL_MANAGER_CANT_DEPLOY",
  VENDOR: "VENDOR",
  READ_ONLY: "READ_ONLY",
};

export const QUESTION_TYPES = {
  SELECT_ALL: "sa",
  TRUE_FALSE: "tf",
  MULTIPLE_CHOICE: "mc",
};

export const AUDIENCETYPES = {
  SELECT_ALL: "S",
  ROLES: "R",
  LOCATIONS: "L",
  OTHER: "O",
  NONE: "NONE",
  MANAGER: "MANAGER",
};

export const DEFINITIONS = {
  totalUniqueUsers:
    "The number of users who have engaged with your content and submitted a module (includes passed and failed submissions)",
  totalUniqueUsersVendor:
    "The number of users that have attempted at least one of your company's modules",
  liveModules:
    "Currently active modules, including those available in the retail content marketplace, or deployed to custom audiences.",
  currentFollowers:
    "Number of users that follow your brand on Spiffy. Users will receive a push notification (with permission) when you launch new content.",
  newFollowersLast7:
    "Number of users that started following your brand during the last 7 days on Spiffy",

  /// Subs and Comps Stats
  submissionsAllTime:
    "The total number of module submissions from all engaged users (includes passed and failed submissions).",
  completionsAllTime:
    "The number of times users successfully completed your modules by reviewing content and achieving the minimum score to pass. This will include retailer assigned completions and those from your followers.",

  completionsAllTimeAssigned:
    "The number of times users have successfully completed your modules (by reviewing content and achieving the minimum score to pass) that were assigned by their employer. This will not include follower completions.",
  submissionsAllTimeAssigned:
    "The total number of module submissions from users who were assigned your modules by their employer, including passed and failed attempts. This will not include follower submissions.",

  completionsAllTimeFollower:
    "The number of times users who follow your company have successfully completed your modules by reviewing content and achieving the minimum score to pass. This will not include retailer assigned completions.",
  submissionsAllTimeFollower:
    "The total number of module submissions from users who FOLLOW your company, including passed and failed attempts. This will not include retailer assigned submissions.",

  averageScore: "The sum of all scores divided by the number of submissions",
  passRate:
    "The percentage of module submissions that achieved the minimum score to pass.",
  averageSubsPerUniqueUser:
    "The average number of module submissions from each engaged user, including passed and failed attempts.",
  averageCompsPerUniqueUser:
    "The average number of module submissions from each engaged user, including passed and failed attempts",
  uniqueUsers:
    "Number of unique users that have attempted this module (either successful or failed) at least once",
  marketplaceDownloads:
    "Number of times companies deployed this module to their teams from the marketplace",
  liveModulesVendor:
    "Number of modules currently deployed to teams or available to users in the marketplace",
  submissions:
    "Number of modules attempted by users, including passed and failed attempts",
  completions:
    "Number of modules that have been successfully completed by users",
  progress: "The average progress of team members within the team",
  singleUserProgress:
    "Number of currently completed assigned modules divided by the number of all currently assigned modules to this user",
  companyProgress: "The average progress of team members within the team",
  numberOfFollowers: "Number of users that follow your brand on the Spiffy App",
  companiesEngaged:
    "Number of companies that have attempted this module (either successful or failed) at least once",
  teamMembers: "The number of team members currently at this location",
  locationCount:
    "This number represents the total number of locations added for your company on Spiffy. This stat is a current total and does not change when using the custom report generator.",
  teamMemberCount:
    "This number represents the total number of team members from your company signed up on Spiffy. This stat is a current total and does not change when using the custom report generator.",
  customModsCount:
    "This stat represents the total number of custom modules currently deployed to your team members. A custom module is a module created by your company. This stat is a current total and does not change when using the custom report generator.",
  marketplaceModsCount:
    "This stat represents the total number of Marketplace modules currently deployed to your team members.  This stat is a current total and does not change when using the custom report generator.",
};

export const ENGAGEMENT_TYPES = {
  ALL: "All",
  ASSIGNED: "Assigned",
  EXTRACREDIT: "Extra Credit",
};

export const ORDER_DIRECTIONS = {
  ASCENDING: "asc",
  DESCENDING: "desc",
};

export const ORDER_BY = {
  ID: "id",
  USERS_NAME: "users_name",
  MODULE_NAME: "quiz_name",
  SCORE: "computed_score",
  CREATED: "created",
  USERS_STATE: "users_state",
};

export const LIBRARY_MODAL_KEYS = {
  ADD_AUDIENCE_AND_LAUNCH: "addAudinceAndLaunch",
  EDIT_AUDIENCE: "editAudeince",
  EDIT_END_DATE: "editEndDate",
  EDIT_START_DATE: "editStartDate",
  PREVIEW: "preview",
  ACTIVITY: "activity",
  CLOSE: "close",
  DELETE: "delete",
  ORG_SHARE: "organizationShare",
  LAUNCH_PENDING: "launchPending",
};

export const returnDefinitionByEngagementType = (
  definition,
  engagementType,
) => {
  if (definition === "completions") {
    if (engagementType === ENGAGEMENT_TYPES.ALL) {
      return DEFINITIONS.completionsAllTime;
    }
    if (engagementType === ENGAGEMENT_TYPES.ASSIGNED) {
      return DEFINITIONS.completionsAllTimeAssigned;
    }
    if (engagementType === ENGAGEMENT_TYPES.EXTRACREDIT) {
      return DEFINITIONS.completionsAllTimeFollower;
    }
  }
  if (definition === "submissions") {
    if (engagementType === ENGAGEMENT_TYPES.ALL) {
      return DEFINITIONS.submissionsAllTime;
    }
    if (engagementType === ENGAGEMENT_TYPES.ASSIGNED) {
      return DEFINITIONS.submissionsAllTimeAssigned;
    }
    if (engagementType === ENGAGEMENT_TYPES.EXTRACREDIT) {
      return DEFINITIONS.submissionsAllTimeFollower;
    }
  }
  return " ";
};

export const GUIDE_TRIGGERS = {
  RETAIL_HOME: "home_page",
  VENDOR_HOME: "home_page",
};

export const VIMEO_BASE_URL = "https://vimeo.com/";

export const WEB_APP_BASE_URL = "https://learn.withspiffy.com/";
export const WEB_APP_STG_URL = "https://web-stg.withspiffy.com/";

export const RETAIL_REPORT_TYPES = {
  MODULES: "MODULES",
  LOCATIONS: "LOCATIONS",
  TEAM_MEMBERS: "TEAM_MEMBERS",
  COUNTS: "OVERALL",
  FEEDBACK: "FEEDBACK",
  POLAR: "POLAR",
  ALL_TIME_SUBMISSIONS: "ALL_TIME_SUBMISSIONS",
  LOCKOUT_REPORT: "LOCKOUT_REPORT",
};

export const STATE_MAP = {
  0: "In Development",
  1: "Pending Release",
  2: "Content Conversion In Progress",
  3: "Live",
  4: "Closed",
  5: "Spiffy Review",
  6: "All",
};

// TODO
// Reuseable change name component
// Reuseable limit text in tables (can i do this in ovverrides?)
// Change to use Zustand for Global State
// Update the stream details page
// audit passed down functions to use useCallback
// look into removing perfect scrollbar
