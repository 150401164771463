import React, { useState } from "react";
import styled from "styled-components";
import { Button, Modal, Paper, Typography, Slider } from "@mui/material";
import API from "src/axios/instances/API";
import SnackbarWrapper from "../../SnackbarWrapper";
import ActionButtonForAccount from "../ActionButtonForAccount";
import { Clock } from "react-feather";

const ModalWrapper = styled(Paper)`
  padding: 20px;
  width: 400px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  overflow-y: scroll;
  border: 2px solid black;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const Item = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1px 0px;
  border-bottom: 1px solid #eeeeee68;

  p {
    font-size: 12px;
    color: black;
  }

  span {
    font-size: 12px;
    color: black;
    font-weight: 500;
  }
`;

const EditLockoutModal = ({
  defaultLockoutDuration,
  setSnackbarText,
  mutate,
  setEditOpen,
}) => {
  const [value, setValue] = useState(defaultLockoutDuration);
  const [isUpdating, setIsUpdating] = useState(false);

  const handleSave = async () => {
    try {
      setIsUpdating(true);
      await API.put("/dashboard/company/details/", {
        module_lockout_duration: value,
      });
      await mutate();
      setIsUpdating(false);
      setSnackbarText("Module lockout duration updated success");
      setEditOpen(false);
    } catch (error) {
      setSnackbarText("Failed to update module lockout error");
      setIsUpdating(false);
    }
  };

  return (
    <ModalWrapper>
      <Typography style={{ fontSize: 16, fontWeight: 600 }}>
        Module Lockout Duration
      </Typography>
      <p style={{ fontSize: 12, color: "darkgrey" }}>
        This controls how long you would like to lock users out of a module
        after repeated failures.
      </p>
      <div style={{ minHeight: 14 }} />
      <div
        style={{
          width: "100%",
          height: 60,
          marginTop: 12,
        }}
      >
        <div
          style={{
            padding: "8px 30px",
            background: "#eeeeee",
            border: "1px solid lightgray",
            borderRadius: 4,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Slider
            step={5}
            size="medium"
            value={value}
            valueLabelDisplay="auto"
            valueLabelFormat={(val) => `${val} minutes`}
            onChange={(_, v) => setValue(v)}
            color="primary"
            min={15}
            max={1440}
          />
        </div>
      </div>
      <Button
        variant="contained"
        color="primary"
        disabled={defaultLockoutDuration == value || isUpdating}
        sx={{ mt: 2, ml: "auto" }}
        onClick={handleSave}
      >
        Save
      </Button>
    </ModalWrapper>
  );
};

const ModuleLockout = ({
  fields,
  defaultLockoutDuration,
  mutate,
  editDisabled,
}) => {
  const [editOpen, setEditOpen] = useState(false);
  const [snackbarText, setSnackbarText] = useState("");
  return (
    <div style={{ width: "100%", marginTop: 12 }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: 8,
          marginBottom: 4,
          width: "100%",
        }}
      >
        <p
          style={{
            fontSize: 11,
            color: "slategrey",
            fontWeight: "600",
          }}
        >
          MODULE LOCKOUT
        </p>
        <div
          style={{
            padding: "1px 4px",
            background: "#338ab7",
            borderRadius: 4,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span style={{ color: "#eeeeee", fontSize: 9, fontWeight: 700 }}>
            NEW FEATURE
          </span>
        </div>
      </div>
      <Item>
        <p>Module Lockout Feature Available:</p>
        <span>{fields?.quiz_lockout_feature ? "Yes" : "No"}</span>
      </Item>
      <Item>
        <p>Module Lockout Duration:</p>
        <span>{`${defaultLockoutDuration} minutes` || "---/--"}</span>
      </Item>
      {fields?.quiz_lockout_feature && (
        // <Button
        //   onClick={() => setEditOpen(true)}
        //   variant="outlined"
        //   size="small"
        //   sx={{ mt: 2 }}
        // >
        //   Edit Duration
        // </Button>
        <>
          <div style={{ height: 4 }} />
          <div style={{ width: "fit-content" }}>
            <ActionButtonForAccount
              disabled={editDisabled}
              handleClick={() => setEditOpen(true)}
              title="Edit Lockout Duration"
              Icon={Clock}
            />
          </div>
        </>
      )}
      <Modal open={editOpen} onClose={() => setEditOpen(false)}>
        <div>
          <EditLockoutModal
            defaultLockoutDuration={defaultLockoutDuration}
            setSnackbarText={setSnackbarText}
            mutate={mutate}
            setEditOpen={setEditOpen}
          />
        </div>
      </Modal>
      <SnackbarWrapper
        setSnackbarText={setSnackbarText}
        snackbarText={snackbarText}
      />
    </div>
  );
};

export default ModuleLockout;
