import React, { useState, useEffect } from "react";
import styled from "styled-components";
import * as Yup from "yup";
import { Formik } from "formik";

import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  Box,
  CircularProgress,
  InputAdornment as MuiInputAdonment,
  Typography,
  Alert,
  TextField,
  Button,
} from "@mui/material";

import { Eye, EyeOff } from "react-feather";
import { Wrapper, TextLink } from "./styles";

import API from "../../../axios/instances/API";
import AuthLayout from "../../../layouts/Auth";

const Subtitle = styled(Typography);

const InputAdornment = styled(MuiInputAdonment)`
  cursor: pointer;
`;

const TimeoutWrapper = styled(Wrapper)`
  min-height: 40px;
`;

const initialValues = {
  newPassword: "",
  confirmPassword: "",
};

const validationSchema = Yup.object().shape({
  newPassword: Yup.string()
    .required("Password is required")
    .max(255, "Password maximum 255 characters")
    .min(8, "Password minimum 8 characters"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], "Passwords are not the same")
    .required("Password confirm is required"),
});

const ResetPassword = ({ computedMatch }) => {
  const [showAlert, setShowAlert] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [resetToken, setResetToken] = useState(false);
  const [tokenExpired, setTokenExpired] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkAndAssignToken = async () => {
      try {
        const { token } = computedMatch.params;
        await API.post("/dashboard/auth/verify-password-token/", {
          token,
        });
        setResetToken(token);
        setIsLoading(false);
      } catch (error) {
        setTokenExpired(true);
        setIsLoading(false);
      }
    };
    checkAndAssignToken();
  }, []);

  const handleFormSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    try {
      await API.post("dashboard/auth/reset-password/", {
        password1: values.newPassword.trim(),
        password2: values.confirmPassword.trim(),
        token: resetToken,
      });
      setShowAlert(true);
      setSubmitting(false);
      resetForm();
    } catch (error) {
      setShowError(true);
      setSubmitting(false);
      resetForm();
      setShowPassword(false);
    }
  };

  if (isLoading) {
    return (
      <AuthLayout>
        <CircularProgress size={30} />
      </AuthLayout>
    );
  }

  if (tokenExpired) {
    return (
      <AuthLayout>
        <TimeoutWrapper align="center">
          <Typography variant="h4">
            Password Reset has timed out.. try again
          </Typography>
          <Box sx={{ mt: 4 }}>
            <Button
              component={Link}
              to="/recover-password"
              variant="contained"
              color="primary"
              fullWidth
            >
              Go Back To Password Reset
            </Button>
          </Box>
        </TimeoutWrapper>
      </AuthLayout>
    );
  }

  return (
    <AuthLayout>
      <Helmet title="Spiffy | Reset Password" />
      <Wrapper>
        <Typography variant="h2" component="h2" align="center">
          Reset Your Password
        </Typography>
        <Subtitle
          component="h5"
          variant="subtitle2"
          align="center"
          color="textSecondary"
          sx={{ mb: 2 }}
        >
          Enter your new Password
        </Subtitle>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleFormSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <TextField
                type={showPassword ? "text" : "password"}
                name="newPassword"
                label="New Password"
                value={values.newPassword}
                error={Boolean(touched.newPassword && errors.newPassword)}
                fullWidth
                helperText={touched.newPassword && errors.newPassword}
                onBlur={handleBlur}
                onChange={handleChange}
                autoComplete="off"
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      onClick={() => setShowPassword((p) => !p)}
                    >
                      {showPassword ? (
                        <Eye fontSize="small" />
                      ) : (
                        <EyeOff fontSize="small" />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                type="password"
                name="confirmPassword"
                label="Confirm Password"
                value={values.confirmPassword}
                error={Boolean(
                  touched.confirmPassword && errors.confirmPassword,
                )}
                fullWidth
                helperText={touched.confirmPassword && errors.confirmPassword}
                onBlur={handleBlur}
                onChange={handleChange}
                autoComplete="off"
              />
              <Box sx={{ mt: 6 }}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  startIcon={isSubmitting && <CircularProgress size={20} />}
                >
                  {isSubmitting ? "Resetting" : "Reset Password"}
                </Button>
              </Box>
              {showAlert && (
                <Alert
                  sx={{ mt: 2 }}
                  severity="success"
                  onClose={() => setShowAlert(false)}
                >
                  New Password Saved!{" "}
                  <TextLink color="inherit" component={Link} to="/">
                    Log In
                  </TextLink>
                </Alert>
              )}
              {showError && (
                <Alert
                  sx={{ mt: 2 }}
                  severity="error"
                  onClose={() => setShowError(false)}
                >
                  Hmm.. something went wrong
                </Alert>
              )}
            </form>
          )}
        </Formik>
      </Wrapper>
    </AuthLayout>
  );
};

export default ResetPassword;

ResetPassword.propTypes = {
  computedMatch: PropTypes.object.isRequired,
};
