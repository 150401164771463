import useSWR from "swr";
import API from "../../../axios/instances/API";

const BASE_URL = "/dashboard/analytics/retail-reports/lockout-list/";

const fetcher = async (url) => {
  const { data } = await API.get(url);
  return data;
};

const useModuleLockoutReport = () => {
  const { data, error, mutate } = useSWR(BASE_URL, fetcher, {
    revalidateOnFocus: false,
  });

  return {
    data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

export default useModuleLockoutReport;
