import React from "react";
import useModuleLockoutReport from "src/swr/hooks/RetailReports/useModuleLockoutReport";
import ErrorBlockWithPermissions from "src/components/UI/ErrorBlockWithPermissions";
import Loader from "../../vendorReports/Loader";
import LockoutTable from "./LockoutTable";

const LockoutReport = () => {
  const { data, isLoading, isError } = useModuleLockoutReport();

  if (isLoading) return <Loader />;
  if (isError) return <ErrorBlockWithPermissions errorObj={isError} />;

  const { lockouts } = data;
  return <LockoutTable lockouts={lockouts} />;
};

export default LockoutReport;
