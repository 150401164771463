import React from "react";
import {
  Home as HomeIcon,
  Monitor,
  PieChart,
  Users,
  Layers,
  Book,
  Tag,
  TrendingUp,
  Cast,
  Globe,
  UserPlus,
  MapPin,
  BarChart,
} from "react-feather";

const homeRoutes = {
  id: "Home",
  path: "/home/",
  icon: <HomeIcon />,
  allowManager: true,
  allowVendor: true,
  allowRetail: true,
};

const adminRoutes = {
  id: "Admins",
  path: "/admin/",
  icon: <Layers />,
  children: null,
  allowManager: false,
  allowVendor: true,
  allowRetail: true,
};

const locationRoutes = {
  id: "Locations",
  path: "/locations/",
  icon: <MapPin />,
  children: null,
  allowManager: true,
  allowVendor: false,
  allowRetail: true,
};

const teamMemberRoutes = {
  id: "Team Members",
  path: "/teammembers/",
  icon: <Users />,
  children: null,
  allowManager: true,
  allowVendor: false,
  allowRetail: true,
};

const managerRoutes = {
  id: "Supervisors",
  path: "/supervisors/",
  icon: <Monitor />,
  children: null,
  allowManager: false,
  allowVendor: false,
  allowRetail: true,
};

const roleRoutes = {
  id: "Roles",
  path: "/roles/",
  icon: <Tag />,
  children: null,
  allowManager: true,
  allowVendor: false,
  allowRetail: true,
};

const reportRoutes = {
  id: "Reports",
  path: "/reports/",
  icon: <BarChart />,
  children: null,
  allowManager: true,
  allowVendor: true,
  allowRetail: true,
};

const streamRoutes = {
  id: "Streams",
  path: "/streams/",
  icon: <TrendingUp />,
  children: null,
  allowManager: false,
  allowVendor: true,
  allowRetail: true,
};

const quizRoutes = {
  id: "Library",
  path: "/modules/",
  icon: <Book />,
  children: null,
  allowManager: true,
  allowVendor: true,
  allowRetail: true,
};

const audienceRoutes = {
  id: "Custom Audiences",
  path: "/audience/",
  icon: <Cast />,
  children: null,
  allowManager: false,
  allowVendor: true,
  allowRetail: true,
};

const followerRoutes = {
  id: "Engaged Users",
  path: "/engaged-users/",
  icon: <UserPlus />,
  children: null,
  allowManager: false,
  allowVendor: true,
  allowRetail: false,
};

const marketplaceRoutes = {
  id: "Marketplace",
  path: "/marketplace/",
  icon: <Globe />,
  children: null,
  allowManager: true,
  allowVendor: false,
  allowRetail: true,
};

const sidebarRoutes = [
  homeRoutes,
  adminRoutes,
  managerRoutes,
  teamMemberRoutes,
  locationRoutes,
  roleRoutes,
  audienceRoutes,
  streamRoutes,
  quizRoutes,
  marketplaceRoutes,
  followerRoutes,
  reportRoutes,
];

export default sidebarRoutes;
