import React, { useContext, Suspense, lazy } from "react";
import { Helmet } from "react-helmet-async";
import PrivateHeader from "../../../components/PrivateHeader";
import { GlobalState } from "../../../store/GlobalState";
import { BarChart } from "react-feather";
import LockoutReport from "./retailReports/lockoutReport";

const RetailReports = lazy(() => import("./retailReports/index.js"));
const VendorReports = lazy(() => import("./vendorReports/index.js"));

const Reports = () => {
  const { state } = useContext(GlobalState);
  const { isRetail, job } = state;

  if (isRetail && job === "M") {
    return (
      <>
        <PrivateHeader header="Lockout Report" Icon={BarChart} />
        <Helmet title="Spiffy Reports" />
        <LockoutReport />
      </>
    );
  }

  if (isRetail) {
    return (
      <>
        <PrivateHeader header="Reports" Icon={BarChart} />
        <Helmet title="Spiffy Reports" />
        <Suspense fallback={<span />}>
          <RetailReports />
        </Suspense>
      </>
    );
  }

  return (
    <>
      <PrivateHeader header="Reports" Icon={BarChart} />
      <Helmet title="Spiffy Reports" />
      <Suspense fallback={<span />}>
        <VendorReports />
      </Suspense>
    </>
  );
};
export default Reports;
